<template>
	<div class="flex" style="flex-direction: column;height: 100%;overflow: hidden;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-form-item label="买方账号："><el-input v-model="searchParm.name" placeholder=""></el-input></el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="卖方账号："><el-input v-model="searchParm.name" placeholder=""></el-input></el-form-item>
					</el-col>
					<el-col :span="10">
						<el-row>
							<el-form-item label="订单时间：">
								<el-col :span="10">
									<el-date-picker v-model="searchParm.begin_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择日期"></el-date-picker>
								</el-col>
								<el-col :span="2" style="text-align: center;"><span>-</span></el-col>
								<el-col :span="10">
									<el-date-picker v-model="searchParm.end_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择日期"></el-date-picker>
								</el-col>
							</el-form-item>
						</el-row>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule" style="overflow: hidden;">
			<el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
				<el-tab-pane label="全部订单" :lazy="true" name=""><list v-if="activeName=='0'" :parentdata="searchParm"></list></el-tab-pane>
				<el-tab-pane label="待确认" :lazy="true" name="1"><list  v-if="activeName=='1'" :parentdata="searchParm" ></list></el-tab-pane>
				<el-tab-pane label="待付款" :lazy="true" name="10"><list  v-if="activeName=='10'" :parentdata="searchParm"></list></el-tab-pane>
				<el-tab-pane label="待发货" :lazy="true" name="20"><list  v-if="activeName=='20'" :parentdata="searchParm"></list></el-tab-pane>
				<el-tab-pane label="待收货" :lazy="true" name="30"><list  v-if="activeName=='30'" :parentdata="searchParm"></list></el-tab-pane>
				<el-tab-pane label="待还货" :lazy="true" name="40"><list v-if="activeName=='40'"  :parentdata="searchParm"></list></el-tab-pane>
				<el-tab-pane label="已还货" :lazy="true" name="50"><list v-if="activeName=='50'"  :parentdata="searchParm"></list></el-tab-pane>
				<el-tab-pane label="已完成" :lazy="true" name="60"><list v-if="activeName=='60'"  :parentdata="searchParm"></list></el-tab-pane>
				<el-tab-pane label="已取消" :lazy="true" name="99"><list  v-if="activeName=='99'" :parentdata="searchParm"></list></el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
import list from './component/list-lease.vue';
export default {
	
	components: {
		list,
		smCard
	},
	data() {
		return {
			tableHeight: 0,
			searchParm: {
				size: 20,
				current: 1
			},
			provinces: [],
			activeName: ''
		};
	},
	methods: {
		//清空搜索
		clearSearch() {
			this.provinces = [];
			this.searchParm = {
				size: 20,
				current: 1
			};
		},
		handleClick(tab) {
			this.searchParm.orderStatus = tab.name;
		}
	},
	mounted() {},
	beforeDestroy() {}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
